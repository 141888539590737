import { ACCOUNT_ROLE_V2 } from '../../../../shared/constants';
import { TRole } from '../../../../shared/types';

export function oldUserRoleToNew(userRole: number | string): TRole {
  const userRoleNr = Number(userRole);
  if (isNaN(userRoleNr)) {
    return userRole as TRole;
  }
  switch (userRoleNr) {
    case 5:
      return ACCOUNT_ROLE_V2.admin.value;
    case 4:
      return ACCOUNT_ROLE_V2.manager.value;
    case 3:
      return ACCOUNT_ROLE_V2.janitor.value;
    case 2:
      return ACCOUNT_ROLE_V2.owner.value;
    case 1:
      return ACCOUNT_ROLE_V2.tenant_normal.value;
    case 0:
      return ACCOUNT_ROLE_V2.tenant_restricted.value;
    default:
      return ACCOUNT_ROLE_V2.owner.value;
  }
}
