import { Timestamp } from 'firebase/firestore';
import { CFirebaseOptions } from './classes';
import { DB, MESSAGE_SCOPE } from './constants';

export type TVoteMode = 'vote' | 'survey';

export type TVoteType = 'online' | 'live' | 'warrant' | 'manual';

export type TTicketDataEntry = {
  id: string;
  i18n: string;
  img: string;
  title?: string;
  description?: string;
  deviceData?: boolean;
  children?: TTicketDataEntry[];
};

export type TSpaceRaw = {
  DATA_SYNC_ABAIMMO_BASE_URL: string;
  DATA_SYNC_ABAIMMO_MANDATE: string;
  DATA_SYNC_ABAIMMO_API_VERSION: string;
  DATA_SYNC_ABAIMMO_REPORT_VERSION: string;
  DATA_SYNC_ABAIMMO_REPORT_POLLING_INTERVAL_MS: string;
  REACT_APP_FB_PROJECT_ID: string;
  REACT_APP_FB_API_KEY: string;
  REACT_APP_FB_AUTH_DOMAIN: string;
  REACT_APP_FB_STORAGE_BUCKET: string;
  REACT_APP_FB_MESSAGING_SENDER_ID: string;
  REACT_APP_FB_APP_ID: string;
  REACT_APP_PLATFORM_TITLE: string;
  REACT_APP_PLATFORM_LANG: string;
  REACT_APP_IMMOAPP_TITLE: string;
  REACT_APP_CLIENT_INVITE_CODE: string;
  REACT_APP_CLIENT_IS_CORPORATION: string;
  REACT_APP_CLIENT_ERP_SYSTEM: string;
  REACT_APP_CLIENT_NAME_EMAIL: string;
  REACT_APP_CLIENT_NAME_BRANDING: string;
  REACT_APP_CLIENT_NAME_FULL: string;
  REACT_APP_CLIENT_NAME_SHORT: string;
  REACT_APP_CLIENT_WEBAPP_URL: string;
  REACT_APP_CLIENT_WEBSITE_URL: string;
  REACT_APP_CLIENT_IMMOMOVE_URL: string;
  REACT_APP_CLIENT_IMMOMOVE_REDIRECT: string;
  REACT_APP_CLIENT_GOOGLE_REVIEW: string;
  REACT_APP_CLIENT_ADMINAPP_URL: string;
  REACT_APP_CLIENT_EMAIL_REPLY_TO: string;
  REACT_APP_CLIENT_EMAIL_SUPERADMIN: string;
  REACT_APP_CLIENT_EMAIL_CONTACT: string;
  REACT_APP_CLIENT_EMAIL_INFOCENTER: string;
  REACT_APP_CLIENT_CONTACT_PHONE: string;
  REACT_APP_CLIENT_ADDRESS_STREET: string;
  REACT_APP_CLIENT_ADDRESS_ZIP: string;
  REACT_APP_CLIENT_ADDRESS_CITY: string;
  REACT_APP_CLOUD_FUNCTION_LOCATION: string;
  REACT_APP_CLOUD_FUNCTION_URL: string;
  REACT_APP_FILES_SOURCE: string;
  REACT_APP_IA: string;
  REACT_APP_IF: string;
  REACT_APP_IM: string;
  REACT_APP_IA_YAROWA_ENV: string;
  REACT_APP_IA_MESSAGES_ALLROLES: string;
  REACT_APP_IA_INFOCENTER_ORDERS_ENABLED: string;
  REACT_APP_IA_AUTO_INVITE_MAX: string;
  REACT_APP_IA_AUTO_INVITE_PRE_EMAIL: string;
  REACT_APP_IA_AUTO_INVITE_TENANTS: string;
  REACT_APP_IA_IMPORT_TIME_EARLY: string;
  REACT_APP_IA_IMPORT_TIME_NEW_DOCUMENTS_NOTIFICATION: string;
  REACT_APP_IA_IMPORT_TIME_MIDDAY: string;
  REACT_APP_IA_IMPORT_TIME_LATE: string;
  REACT_APP_IA_SHOW_LICENSE_NOTIFICATION: string;
  REACT_APP_INIT_MANDATE_ACTIVE: string;
  SYNCME_CREDENTIALS_IMMO_TOP_1: Record<string, string>;
  SYNCME_CREDENTIALS_IMMO_TOP_2: Record<string, string>;
};

export interface IVersionAppData {
  version: string;
}

export interface ICommunicationReceiver {
  uid: string;
  mandate_id: number | null;
  mandate_address: IMandateAddress | null;
  property_id: number | null;
  language: TLang;
  role: TRole;
  email: string;
  first_name: string;
  last_name: string;
  communicationEnabled: boolean;
}

export type TReportDbCollection =
  | DB.ia_messages
  | DB.ia_repairs
  | DB.ia_pinboard
  | DB.ia_votes;
export const TICKET_TYPES = [
  'repair',
  'reservation',
  'keyorder',
  'nameplate',
  'document',
  'voteitem',
  'other',
] as const;
export type TReportTicket = (typeof TICKET_TYPES)[number];
export type TReportSubmit = TReportTicket | 'pinboard';
export type TReportAll = TReportSubmit | 'message' | 'votes'; // Note: This can be changed to "vote" once everyone is on v2.3.0

export type TDocumentEntry =
  | 'rootMandate'
  | 'rootMandateInactive'
  | 'rootPerson'
  | 'personFolder'
  | 'folder'
  | 'file';

export type TLang = 'de' | 'en' | 'fr' | 'it';

export type TFirebaseConfigWebAndFunctionsLocation =
  | CFirebaseOptions & { functionsLocation: string };

export interface IRepairTimeline {
  date_creation: Timestamp;
  description: string;
  repair_status: number;
  first_name: string;
  last_name: string;

  // Optional
  uid?: string;

  // Client side fields
  expanded?: boolean;
  editMode?: boolean;
}

export interface IFileManagerSettings {
  default_access_group: string;
}

export interface ICommonSettings {
  auto_deactivate_users: boolean;
  auto_invite_include_janitors: boolean;
  auto_invite_users: boolean;
  auto_permit_users: boolean;
  auto_send_emails: boolean;
  auto_send_new_documents_push: boolean;
  auto_send_push: boolean;

  data_reset_time?: Timestamp;
  import_only_active_mandates_data?: boolean;
}

export interface ISettings {
  common_settings: ICommonSettings;
  rclone: any;
}

export interface IComment {
  date_creation: Timestamp;
  time_created: Timestamp;
  description: string;
  first_name: string;
  last_name: string;
  uid: string;
  status: 'original' | 'deleted' | 'edited';

  // Client side fields
  userProfileThumb?: string;
  editMode?: boolean;
}

export interface IVoteQuorum {
  incl_abst: boolean;
  incl_all: boolean;
  incl_quota: boolean;
  incl_tie: boolean;
  majority_perc: number;
  min_participants: number;
}

export interface ICommunicationFilter {
  key: string;
  defaultValue: boolean;
  returnOnlyActive: boolean;
  returnOnlySender: boolean;
  isComment: boolean;
  returnInternalExternalAll: MESSAGE_SCOPE;
}

export type TSentry = {
  Error(errorObject: string | Error | unknown, extras?: string[]): void;
  Warning(message: string, extras?: string[]): void;
  Info(message: string, extras?: string[]): void;
};

export type TEmailDeliveryResult = {
  status: string;
  date: string;

  recipient?: string;
  response?: string;
};

export type TObjectResident = {
  end_date: any;
  person_id: number;
  relationship_id: number;
  relationship_name: string;
  start_date: any;
};

export type TEmailDeliverySummary = {
  failed: number;
  processed: number;
  sent: number;
};

export interface IWarrantReceiver {
  email: string;
  name: string;
  uid: string;
  person_id?: number; // Optional can be removed once everyone is on ImmoApp 2.3.0
}

export interface IWebPushTopic {
  id: string;
  subscribe: boolean;
}

export interface IPushMessage {
  topic: string;
  email: string;
  title: string;
  body: string;
  extraPayload: Record<string, string>;
}

export interface IFaqSection {
  title: ITranslatedString;
  items: IFaqItem[];
}

export interface IFaqItem {
  head?: ITranslatedString;
  body?: ITranslatedString;
}

export interface ITranslatedString {
  de?: string;
  en?: string;
  fr?: string;
  it?: string;
}

export interface IEligibleParticipant {
  objects: IEligibleParticipantObjects[];
  owners: IEligibleParticipantOwners[];
  rel_id: number;
  rel_name: string;
}

export interface IEligibleParticipantObjects {
  count_head: Record<string, number>;
  count_object: number;
  object_id_full: string;
  object_name: string;
  property_id_full: string;
  quota: number;
}

export interface IEligibleParticipantOwners {
  name_first: string;
  name_last: string;
  person_id: number;
}

export interface ISenderUser {
  email: string;
  first_name: string;
  last_name: string;
  phone?: string;
  uid?: string;
}

export interface IHandyMan {
  email: string;
  first_name: string;
  last_name: string;
  notify_status: number;

  uid?: string;
  message?: string;
}

export interface IContactEmployee {
  first_name: string;
  last_name: string;

  phone_number?: string;
  email_external?: string;
  company?: string;
  extra_info?: string;
}

export interface IContactResident {
  email: string;
  phoneMobile?: string;
  phoneWork?: string;
  phoneHome?: string;
}

export interface IMandateAddress {
  city: string;
  street: string;
  zip: number;
}

export interface IEmailDeliveryReportSummary {
  sent: number;
  failed: number;
  processed: number;
}

export interface IContactDetails {
  email: string;
  phone_home?: string;
  phone_mobile?: string;
  phone_work?: string;
}

export interface IYarowaService {
  api_endpoint: string;
  param_key_immodigi_id: string;
  param_key_provider_id: string;
  param_key_zip: string;
  param_key_action: string;
  param_value_action: string;
}

export interface IMandateAccess {
  pinboard?: boolean;
  form_guide?: boolean;
}

export interface IReportProperty {
  mandate_id: number;
  property_id: number;
  street: string;
}

export interface IStorageFile {
  name: string;
  path: string;
  thumb?: string;

  // Used in vote files for easy reference
  id?: string;
}

export interface IReservationPlace {
  weekdays: number[];
  interval: number;
  name: string;
  time_start: string;
  time_end: string;
  confirmation_needed: boolean;

  // Client-side fields
  actionName?: string;
  index?: number;
}

export interface IReservationDetails {
  duration: number;
  place: string; // One of the dynamic values defined in the admin-app
}

export interface INewDocument {
  name: string;
  path: string;
  path_visible: string;
  date_added: string;
}

export interface IMandateData {
  active: boolean;
  name: string;
  role: TRole;
  show_as_contact?: boolean;
  limited_ticket_access?: boolean;
}

export const ROLES_ORDER: string[] = [
  'tenant_restricted',
  'tenant_normal',
  'owner',
  'janitor',
  'handyman',
  'manager',
  'admin',
];

export type TRole = (typeof ROLES_ORDER)[number];

export interface IDeviceData {
  serial_number?: string;
  manufacturer?: string;
}

export interface IKeyOrderData {
  quantity: number;
  label?: string;
}

export interface INameplateOrderData {
  row1: string;
  row2?: string;
}

export interface IDocumentsPushReceiver {
  uid: string;
  language: TLang;
  email: string;
  documents: INewDocument[];
}

export interface ITenant {
  first_name: string;
  last_name: string;
  email: string;
  mandate_name: string;
  mandate_id: number;
}

export interface IClientInfo {
  name: string;
  namefull: string;
  nameshort: string;
  address1: string;
  address2: string;
  address3: string;
  email: string;
  phonedisplay: string;
  phonelink: string;
  urldisplay: string;
  urllink: string;
}

export interface IAccountRoleV2 {
  value: TRole;
  intl_id: string;
  color: string;
}

export interface IAccountSource {
  value: string;
  intl_id: string;
}

export interface IEmailUnsubscribe {
  value: string;
}

export interface IRepairStatus {
  value: 0 | 1 | 2;
  color_name: string;
  intl_id: string;
  intl_id_reservation: string;
}

export interface IVoteUserDetails {
  name_first: string;
  name_last: string;
  uid: string | null;
  relationship_ids: number[];

  person_id?: number;
}

export interface IRootFolderData {
  name: string;
  orderId: number;
}

export interface IDocumentFolderPattern {
  type: string;
  id: number;
}

export interface YIFile {
  fileName: string;
  content: string; // Base64
  type: 'photo' | 'report';
  attributeName: string; // TODO check what is allowed for this field, currently we only use "CaseDocuments"
  visibleFor: string[]; // TODO check what is allowed for this field, currently we only use "PrincipalUser"
}
